import axios from 'axios'
import { endpoint } from '@/common/constants'
import resource from '@/api/resourceChatbot'

const AxiosCancelToken = axios.CancelToken
const NAMESPACE = 'ASK_AIRENE'

export default {
  /**
   * @description Initiate for suggestion
   * @param {String} roomID
   * @returns {Promise}
   */
  postSuggestion(roomID) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_POST_SUGGESTIONS`
    const request = resource.post(
      endpoint.v1.chatbot.gpt.ask_airene.suggestion.post.replace(
        ':room_id',
        roomID
      ),
      {},
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },
  /**
   * @description Get suggestions
   * @param {String} roomID
   * @returns {Promise}
   */
  getSuggestion(roomID) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_SUGGESTIONS`
    const request = resource.get(
      endpoint.v1.chatbot.gpt.ask_airene.suggestion.get.replace(
        ':room_id',
        roomID
      ),
      {
        params: {},
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },
  /**
   * @description Get source
   * @param {String} sourceID
   * @returns {Promise}
   */
  getSourceUrl(sourceID) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_SOURCE`
    const request = resource.get(
      endpoint.v1.chatbot.gpt.ask_airene.knowledge.url.replace(
        ':source_id',
        sourceID
      ),
      {
        params: {},
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },
  /**
   * @description Get source
   * @param {String} sourceID
   * @returns {Promise}
   */
  getAblyConversationHistory(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_ABLY_HISTORY`
    const request = resource.get(
      endpoint.v1.chatbot.gpt.ask_airene.history.get.replace(
        ':room_id',
        params.room_id
      ),
      {
        params,
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },
}
